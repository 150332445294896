import React from 'react';
import { Route, Switch } from 'react-router-dom';
import List from './SubmissionList';
import Item from './SubmissionItem';

const Form = () => (
  <div>
    <Switch>
      <Route exact path="/form/:formId/submission" component={List} />
      <Route path="/form/:formId/submission/:submissionId" component={Item} />
    </Switch>
  </div>
);

export default Form;
