import { Formio as Formiojs } from 'react-formio';
import { selectTemplate } from '../../selectors/FormTemplate';

export const FORM_TEMPLATE_SUCCESS = 'FORM_TEMPLATE_SUCCESS';
export const FORM_TEMPLATE_FAILURE = 'FORM_TEMPLATE_FAILURE';
export const SET_BUILDER_TEMPLATE = 'SET_BUILDER_TEMPLATE';

export const receiveFormTemplate = (id, template, url) => ({
  type: FORM_TEMPLATE_SUCCESS,
  template,
  id,
  url
});

export const failFormTemplate = (id, err) => ({
  type: FORM_TEMPLATE_FAILURE,
  error: err,
  id
});

export const setBuilderTemplate = builderTemplateType => ({
  type: SET_BUILDER_TEMPLATE,
  builderType: builderTemplateType
});

export const getFormTemplate = (id = '', done = () => {}) => (
  dispatch,
  getState
) => {
  // Check to see if the form is already loaded.
  const form = selectTemplate(id, getState());
  if (
    form &&
    form.components &&
    Array.isArray(form.components) &&
    form.components.length &&
    form._id === id
  ) {
    return;
  }

  const path = `${Formiojs.getProjectUrl()}${`/form/${id}`}`;
  const formio = new Formiojs(path);
  formio
    .loadForm()
    .then(result => {
      dispatch(receiveFormTemplate(id, result, path));
      done(null, result);
    })
    .catch(result => {
      dispatch(failFormTemplate(id, result));
      done(result);
    });
};
