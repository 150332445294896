import * as types from '../../actions/FormTags';

export default function tags() {
  const initialState = {
    tags: []
  };

  return (state = initialState, action) => {
    if (action.type === types.ADD_FORM_TAGS) {
      const newTags = [...state.tags, ...action.tags];
      return {
        ...state,
        tags: newTags
      };
    }
    if (action.type === types.CLEAR_FORM_TAGS) {
      return initialState;
    }
    return state;
  };
}
