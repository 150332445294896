import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectRoot } from 'react-formio';
import Loading from './Loading';
import { initAuth } from '../store/actions/Auth';
import { useAppContext } from '../hooks/useAppContext';

const Hero = () => {
  const {
    state: { formContext }
  } = useAppContext();
  const dispatch = useDispatch();
  const login = () => {
    dispatch(initAuth());
  };
  const { authenticated, isActive } = useSelector(state =>
    selectRoot('auth', state)
  );

  const AvailableActions = () =>
    authenticated ? (
      <>
        <Link to={{ pathname: './form/tags' }}>
          <button type="button" className="btn btn-primary btn-lg">
            Create New Form
          </button>
        </Link>
      </>
    ) : (
      <button type="button" className="btn btn-primary btn-lg" onClick={login}>
        Login
      </button>
    );

  const EnhancedAvailableActions = () =>
    isActive ? <Loading /> : <AvailableActions />;

  return (
    <div className="jumbotron jumbotron-fluid">
      <div className="container">
        <h1 className="display-4">
          Create Forms for {(formContext || '').toUpperCase()}
        </h1>
        <p className="lead">
          Create {formContext} forms for use on the station websites.
        </p>
        <hr className="my-4" />
        <div className="lead">
          <EnhancedAvailableActions />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Hero);
