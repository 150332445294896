import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { getSubmissions, selectRoot, selectError, Errors } from 'react-formio';
import SubmissionGrid from '../../../../../../components/SubmissionGrid';
import Loading from '../../../../../../components/Loading';
import DownloadCSV from '../DownloadCSV';

const operations = [
  {
    action: 'view',
    buttonType: 'primary',
    icon: '',
    permissionsResolver() {
      return true;
    },
    title: 'View Details'
  },
  {
    action: 'edit',
    buttonType: 'secondary',
    icon: 'edit',
    permissionsResolver() {
      return false;
    },
    title: 'Edit'
  },
  {
    action: 'delete',
    buttonType: 'danger',
    icon: 'trash',
    permissionsResolver() {
      return false;
    }
  }
];

function SubmissionTable({ form, params, searchEmail, limit, handleLimit }) {
  const dispatch = useDispatch();

  const initialQuery = {
    limit,
    sort: '-created'
  };

  const { submissions, isLoading, errors } = useSelector(state => {
    const newSubmissions = selectRoot('submissions', state);
    return {
      submissions: newSubmissions,
      isLoading: newSubmissions.isActive,
      errors: [selectError('submissions', state), selectError('form', state)]
    };
  });

  const onAction = (submission, action) => {
    switch (action) {
      case 'view':
      case 'row':
        dispatch(push(`/form/${params.formId}/submission/${submission._id}`));
        break;
      default:
    }
  };

  const retrieveSubmissions = (page, query) => {
    dispatch(getSubmissions('submissions', page, query, params.formId));
  };

  useEffect(() => {
    if (searchEmail) {
      retrieveSubmissions(1, {
        ...initialQuery,
        'data.email__regex': `/${searchEmail}/`
      });
    } else {
      retrieveSubmissions(1, { ...initialQuery });
    }
  }, [searchEmail]);

  useEffect(() => {
    retrieveSubmissions(1, { ...initialQuery });
  }, [limit]);

  if (isLoading) {
    return <Loading />;
  }

  const onPageSizeChanged = selectedLimit => {
    handleLimit(selectedLimit);
  };

  return (
    <div>
      {/* <DownloadCSV
        components={form.components}
        submissions={submissions}
        formId={form._id}
        title={form.title}
        searchEmail={searchEmail}
      /> */}
      <Errors errors={errors} />
      <SubmissionGrid
        submissions={submissions}
        form={form}
        onAction={onAction}
        getSubmissions={retrieveSubmissions}
        onPageSizeChanged={onPageSizeChanged}
        operations={operations}
      />
    </div>
  );
}

SubmissionTable.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  params: PropTypes.objectOf(PropTypes.any).isRequired,
  searchEmail: PropTypes.string,
  limit: PropTypes.number,
  handleLimit: PropTypes.func.isRequired
};

SubmissionTable.defaultProps = {
  searchEmail: '',
  limit: 25
};

export default SubmissionTable;
