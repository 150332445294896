import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormBuilder } from 'react-formio';
import _cloneDeep from 'lodash/cloneDeep';
import _camelCase from 'lodash/camelCase';
import _set from 'lodash/set';
import disableBuilderComponents from '../../utilities/disableBuilderComponents';
import { disableBuilderKeys } from '../../config';

export default class FormEdit extends Component {
  static propTypes = {
    form: PropTypes.objectOf(PropTypes.string, PropTypes.object).isRequired,
    options: PropTypes.objectOf(PropTypes.string, PropTypes.object).isRequired,
    saveForm: PropTypes.func.isRequired,
    saveText: PropTypes.string
  };

  static defaultProps = {
    saveText: 'save'
  };

  constructor(props) {
    super(props);

    const { form } = props;
    this.formBuilder = React.createRef();

    this.state = {
      form: form
        ? _cloneDeep(form)
        : {
            title: '',
            name: '',
            path: '',
            display: 'form',
            type: 'form',
            components: []
          }
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.form &&
      (prevState.form._id !== nextProps.form._id ||
        prevState.form.modified !== nextProps.form.modified)
    ) {
      return {
        form: _cloneDeep(nextProps.form)
      };
    }

    return null;
  }

  componentDidMount() {
    this.disableComponents();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Only update if key form info has changed. The builder handles form component changes itself.
    return (
      this.state.form.title !== nextState.form.title ||
      this.state.form.name !== nextState.form.name ||
      this.state.form.path !== nextState.form.path ||
      this.state.form.display !== nextState.form.display ||
      this.state.form.type !== nextState.form.type
    );
  }

  componentDidUpdate() {
    this.disableComponents();
  }

  disableComponents = async () => {
    const { form } = this.state;
    if (form.components.length > 0 && this.formBuilder.current) {
      // wait for the builder to be loaded.
      await this.formBuilder.current.builderReady;
      disableBuilderComponents(disableBuilderKeys, form.components);
    }
  };

  formChange = form => {
    this.setState(prevState => ({
      form: { ...prevState.form, ...form }
    }));
  };

  saveForm() {
    const { saveForm } = this.props;
    if (saveForm && typeof saveForm === 'function') {
      saveForm(this.state.form);
    }
  }

  handleChange(path, event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState(prev => {
      const form = _cloneDeep(prev.form);
      _set(form, path, value);

      // If setting title, autogenerate name and path as well.
      if (path === 'title' && !form._id) {
        form.name = _camelCase(value);
        form.path = _camelCase(value).toLowerCase();
      }

      return {
        ...prev,
        form
      };
    });
  }

  render() {
    const { form } = this.state;
    const { saveText } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div id="form-group-title" className="form-group">
              <label htmlFor="title" className="control-label field-required">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                placeholder="Enter the form title"
                value={form.title || ''}
                onChange={event => this.handleChange('title', event)}
              />
            </div>
          </div>
        </div>
        <FormBuilder
          key={form._id}
          form={form}
          options={this.props.options}
          onChange={this.formChange}
          ref={this.formBuilder}
          onSaveComponent={() => this.disableComponents()}
          onCancelComponent={() => this.disableComponents()}
          onDeleteComponent={() => this.disableComponents()}
        />
        <div
          id="save-buttons"
          className="col-lg-8 col-md-6 col-sm-6 save-buttons pull-right mt-4"
        >
          <div className="form-group pull-right">
            <button
              type="button"
              className="btn btn-primary btn-lg"
              onClick={() => this.saveForm()}
            >
              {saveText}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
