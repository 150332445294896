import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

export default function CopyToClipboard({ stringToCopy, btnText }) {
  const textAreaRef = useRef(null);

  function copyToClipboard() {
    textAreaRef.current.select();
    document.execCommand('copy');
    toast.success('Link copied');
  }

  return (
    <div>
      {document.queryCommandSupported('copy') ? (
        <button
          type="button"
          className="btn btn-primary"
          onClick={copyToClipboard}
        >
          {btnText}
        </button>
      ) : (
        <span>{stringToCopy}</span>
      )}
      <form className="hide-selection-text">
        <textarea ref={textAreaRef} value={stringToCopy} />
      </form>
    </div>
  );
}

CopyToClipboard.propTypes = {
  stringToCopy: PropTypes.string.isRequired,
  btnText: PropTypes.string
};

CopyToClipboard.defaultProps = {
  btnText: ''
};
