import * as types from '../../actions/FormTemplate';

export default function formTemplate() {
  const initialState = {
    templates: {},
    builderType: 'basicForm'
  };

  return (state = initialState, action) => {
    switch (action.type) {
      case types.FORM_TEMPLATE_SUCCESS:
        // eslint-disable-next-line no-case-declarations
        const newState = {
          templates: {
            ...state.templates,
            [action.id]: { ...action.template }
          }
        };
        return {
          ...state,
          ...newState
        };
      case types.FORM_TEMPLATE_FAILURE:
        // eslint-disable-next-line no-case-declarations
        const newErrorState = {
          templates: {
            ...state.templates,
            [action.id]: { error: action.error }
          }
        };
        return {
          ...state,
          ...newErrorState
        };
      case types.SET_BUILDER_TEMPLATE:
        return {
          ...state,
          builderType: action.builderType
        };
      default:
        return state;
    }
  };
}
