const {
  REACT_APP_PROJECT_URL,
  REACT_APP_API_URL,
  REACT_APP_ADMIN_PANEL_KEY,
  REACT_APP_PERSONAL_DETAILS_PANEL_KEY,
  REACT_APP_ADDRESS_DETAILS_PANEL_KEY,
  REACT_APP_RECAPTURE_PANEL_KEY,
  REACT_APP_SUBMIT_BTN_KEY,
  REACT_APP_FILE_UPLOAD_KEY,
  REACT_APP_LEGAL_STATION_CHECKBOX_KEY,
  REACT_APP_LEGAL_THIRD_PARTY_CHECKBOX_KEY,
  REACT_APP_LEGAL_TERMS_CHECKBOX_KEY,
  REACT_APP_FIRST_NAME_KEY,
  REACT_APP_LAST_NAME_KEY,
  REACT_APP_EMAIL_KEY,
  REACT_APP_STATE_KEY,
  REACT_APP_NETWORK_KEY,
  REACT_APP_EMAIL_SHA256_KEY,
  REACT_APP_CM_SOURCE_KEY
} = process.env;

const query = {};
window.location.search
  .substr(1)
  .split('&')
  .forEach(item => {
    query[item.split('=')[0]] =
      item.split('=')[1] && decodeURIComponent(item.split('=')[1]);
  });

const PROJECT_URL = query.projectUrl || REACT_APP_PROJECT_URL;
const API_URL = query.apiUrl || REACT_APP_API_URL;

export const AppConfig = {
  projectUrl: PROJECT_URL,
  apiUrl: API_URL
};

// Determine which builder components to disable when the form builder is loaded.
export const disableBuilderKeys = [
  REACT_APP_ADMIN_PANEL_KEY,
  REACT_APP_PERSONAL_DETAILS_PANEL_KEY,
  REACT_APP_ADDRESS_DETAILS_PANEL_KEY,
  REACT_APP_RECAPTURE_PANEL_KEY,
  REACT_APP_SUBMIT_BTN_KEY,
  REACT_APP_FILE_UPLOAD_KEY,
  REACT_APP_LEGAL_STATION_CHECKBOX_KEY,
  REACT_APP_LEGAL_THIRD_PARTY_CHECKBOX_KEY,
  REACT_APP_LEGAL_TERMS_CHECKBOX_KEY
];

// Tell the form builder validator which components must be present before a form is created.
export const builderValidatorKeys = [
  REACT_APP_LEGAL_TERMS_CHECKBOX_KEY,
  REACT_APP_FIRST_NAME_KEY,
  REACT_APP_LAST_NAME_KEY,
  REACT_APP_EMAIL_KEY,
  REACT_APP_STATE_KEY,
  REACT_APP_NETWORK_KEY,
  REACT_APP_EMAIL_SHA256_KEY,
  REACT_APP_CM_SOURCE_KEY
];
