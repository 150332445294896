// Config setting to display the Form builder in the way SCA wants it.
// The majority of the component setting are hidden from the user.
// To have full access the component settings login on the main Formio website.
// Use Formio.Components.components.textfield.editForm() in the console window to get the name of each field.
// See https://github.com/formio/formio.js/issues/1056 for further information.
export default {
  builder: {
    basic: false,
    advanced: false,
    data: false,
    premium: false,
    customBasic: {
      title: 'Components',
      weight: 10,
      default: true,
      components: {
        textfield: true,
        textarea: true,
        datetime: false,
        checkbox: true,
        radio: true,
        select: true
      }
    },
    layout: {
      weight: 10,
      default: true,
      components: {
        table: false,
        well: false,
        tabs: false,
        fieldset: false,
        htmlelement: false,
        content: false
      }
    }
  },
  editForm: {
    textfield: [
      {
        key: 'api',
        ignore: true
      },
      {
        key: 'data',
        ignore: true
      },
      {
        key: 'conditional',
        ignore: true
      },
      {
        key: 'logic',
        ignore: true
      },
      {
        key: 'layout',
        ignore: true
      },
      {
        key: 'display',
        components: [
          {
            key: 'labelPosition',
            ignore: true
          },
          {
            key: 'widget.type',
            ignore: true
          },
          {
            key: 'description',
            ignore: true
          },
          {
            key: 'errorLabel',
            ignore: true
          },
          {
            key: 'inputMask',
            ignore: true
          },
          {
            key: 'allowMultipleMasks',
            ignore: true
          },
          {
            key: 'inputMasks',
            ignore: true
          },
          {
            key: 'prefix',
            ignore: true
          },
          {
            key: 'suffix',
            ignore: true
          },
          {
            key: 'customClass',
            ignore: true
          },
          {
            key: 'tabindex',
            ignore: true
          },
          {
            key: 'persistent',
            ignore: true
          },
          {
            key: 'showWordCount',
            ignore: true
          },
          {
            key: 'showCharCount',
            ignore: true
          },
          {
            key: 'multiple',
            ignore: true
          },
          {
            key: 'clearOnHide',
            ignore: true
          },
          {
            key: 'protected',
            ignore: true
          },
          {
            key: 'hidden',
            ignore: true
          },
          {
            key: 'mask',
            ignore: true
          },
          {
            key: 'disabled',
            ignore: true
          },
          {
            key: 'autofocus',
            ignore: true
          },
          {
            key: 'tableView',
            ignore: true
          },
          {
            key: 'alwaysEnabled',
            ignore: true
          }
        ]
      },
      {
        key: 'validation',
        components: [
          {
            key: 'unique',
            ignore: true
          },
          {
            key: 'validate.minLength',
            ignore: true
          },
          {
            key: 'validate.maxLength',
            ignore: true
          },
          {
            key: 'validate.minWords',
            ignore: true
          },
          {
            key: 'validate.maxWords',
            ignore: true
          },
          {
            key: 'validate.pattern',
            ignore: true
          },
          {
            key: 'validateOn',
            ignore: true
          },
          {
            key: 'validate.customMessage',
            ignore: true
          },
          {
            key: 'custom-validation-js',
            ignore: true
          },
          {
            key: 'json-validation-json',
            ignore: true
          }
        ]
      }
    ],
    textarea: [
      {
        key: 'api',
        ignore: true
      },
      {
        key: 'data',
        ignore: true
      },
      {
        key: 'conditional',
        ignore: true
      },
      {
        key: 'logic',
        ignore: true
      },
      {
        key: 'layout',
        ignore: true
      },
      {
        key: 'display',
        components: [
          {
            key: 'labelPosition',
            ignore: true
          },
          {
            key: 'description',
            ignore: true
          },
          {
            key: 'rows',
            ignore: true
          },
          {
            key: 'errorLabel',
            ignore: true
          },
          {
            key: 'editor',
            ignore: true
          },
          {
            key: 'autoExpand',
            ignore: true
          },
          {
            key: 'isUploadEnabled',
            ignore: true
          },
          {
            key: 'prefix',
            ignore: true
          },
          {
            key: 'suffix',
            ignore: true
          },
          {
            key: 'customClass',
            ignore: true
          },
          {
            key: 'tabindex',
            ignore: true
          },
          {
            key: 'persistent',
            ignore: true
          },
          {
            key: 'showWordCount',
            ignore: true
          },
          {
            key: 'showCharCount',
            ignore: true
          },
          {
            key: 'multiple',
            ignore: true
          },
          {
            key: 'clearOnHide',
            ignore: true
          },
          {
            key: 'protected',
            ignore: true
          },
          {
            key: 'hidden',
            ignore: true
          },
          {
            key: 'mask',
            ignore: true
          },
          {
            key: 'disabled',
            ignore: true
          },
          {
            key: 'autofocus',
            ignore: true
          },
          {
            key: 'tableView',
            ignore: true
          },
          {
            key: 'alwaysEnabled',
            ignore: true
          }
        ]
      },
      {
        key: 'validation',
        components: [
          {
            key: 'unique',
            ignore: true
          },
          {
            key: 'validate.minLength',
            ignore: true
          },
          {
            key: 'validate.maxLength',
            ignore: true
          },
          {
            key: 'validate.minWords',
            ignore: true
          },
          {
            key: 'validate.pattern',
            ignore: true
          },
          {
            key: 'validateOn',
            ignore: true
          },
          {
            key: 'validate.customMessage',
            ignore: true
          },
          {
            key: 'custom-validation-js',
            ignore: true
          },
          {
            key: 'json-validation-json',
            ignore: true
          }
        ]
      }
    ],
    checkbox: [
      {
        key: 'api',
        ignore: true
      },
      {
        key: 'data',
        ignore: true
      },
      {
        key: 'conditional',
        ignore: true
      },
      {
        key: 'logic',
        ignore: true
      },
      {
        key: 'layout',
        ignore: true
      },
      {
        key: 'display',
        components: [
          {
            key: 'labelPosition',
            ignore: true
          },
          {
            key: 'labelWidth',
            ignore: true
          },
          {
            key: 'labelMargin',
            ignore: true
          },
          {
            key: 'description',
            ignore: true
          },
          {
            key: 'shortcut',
            ignore: true
          },
          {
            key: 'inputType',
            ignore: true
          },
          {
            key: 'errorLabel',
            ignore: true
          },
          {
            key: 'customClass',
            ignore: true
          },
          {
            key: 'tabindex',
            ignore: true
          },
          {
            key: 'persistent',
            ignore: true
          },
          {
            key: 'multiple',
            ignore: true
          },
          {
            key: 'clearOnHide',
            ignore: true
          },
          {
            key: 'protected',
            ignore: true
          },
          {
            key: 'hidden',
            ignore: true
          },
          {
            key: 'mask',
            ignore: true
          },
          {
            key: 'disabled',
            ignore: true
          },
          {
            key: 'autofocus',
            ignore: true
          },
          {
            key: 'tableView',
            ignore: true
          },
          {
            key: 'alwaysEnabled',
            ignore: true
          }
        ]
      },
      {
        key: 'validation',
        components: [
          {
            key: 'unique',
            ignore: true
          },
          {
            key: 'validate.minLength',
            ignore: true
          },
          {
            key: 'validate.maxLength',
            ignore: true
          },
          {
            key: 'validate.minWords',
            ignore: true
          },
          {
            key: 'validate.pattern',
            ignore: true
          },
          {
            key: 'validateOn',
            ignore: true
          },
          {
            key: 'validate.customMessage',
            ignore: true
          },
          {
            key: 'custom-validation-js',
            ignore: true
          },
          {
            key: 'json-validation-json',
            ignore: true
          }
        ]
      }
    ],
    radio: [
      {
        key: 'api',
        ignore: true
      },
      {
        key: 'data',
        ignore: true
      },
      {
        key: 'conditional',
        ignore: true
      },
      {
        key: 'logic',
        ignore: true
      },
      {
        key: 'layout',
        ignore: true
      },
      {
        key: 'display',
        components: [
          {
            key: 'labelPosition',
            ignore: true
          },
          {
            key: 'labelWidth',
            ignore: true
          },
          {
            key: 'labelMargin',
            ignore: true
          },
          {
            key: 'optionsLabelPosition',
            ignore: true
          },
          {
            key: 'values',
            components: [
              {
                key: 'value',
                ignore: true
              },
              {
                key: 'shortcut',
                ignore: true
              }
            ]
          },
          {
            key: 'description',
            ignore: true
          },
          {
            key: 'inline',
            ignore: true
          },
          {
            key: 'errorLabel',
            ignore: true
          },
          {
            key: 'customClass',
            ignore: true
          },
          {
            key: 'tabindex',
            ignore: true
          },
          {
            key: 'persistent',
            ignore: true
          },
          {
            key: 'multiple',
            ignore: true
          },
          {
            key: 'clearOnHide',
            ignore: true
          },
          {
            key: 'protected',
            ignore: true
          },
          {
            key: 'hidden',
            ignore: true
          },
          {
            key: 'mask',
            ignore: true
          },
          {
            key: 'disabled',
            ignore: true
          },
          {
            key: 'autofocus',
            ignore: true
          },
          {
            key: 'tableView',
            ignore: true
          },
          {
            key: 'alwaysEnabled',
            ignore: true
          }
        ]
      },
      {
        key: 'validation',
        components: [
          {
            key: 'unique',
            ignore: true
          },
          {
            key: 'validate.minLength',
            ignore: true
          },
          {
            key: 'validate.maxLength',
            ignore: true
          },
          {
            key: 'validate.minWords',
            ignore: true
          },
          {
            key: 'validate.pattern',
            ignore: true
          },
          {
            key: 'validateOn',
            ignore: true
          },
          {
            key: 'validate.customMessage',
            ignore: true
          },
          {
            key: 'custom-validation-js',
            ignore: true
          },
          {
            key: 'json-validation-json',
            ignore: true
          }
        ]
      }
    ],
    columns: [
      {
        key: 'api',
        ignore: true
      },
      {
        key: 'conditional',
        ignore: true
      },
      {
        key: 'logic',
        ignore: true
      },
      {
        key: 'layout',
        ignore: true
      },
      {
        key: 'display',
        components: [
          {
            key: 'label',
            ignore: true
          },
          {
            key: 'hideLabel',
            ignore: true
          },
          {
            key: 'placeholder',
            ignore: true
          },
          {
            key: 'tooltip',
            ignore: true
          },
          {
            key: 'labelPosition',
            ignore: true
          },
          {
            key: 'labelWidth',
            ignore: true
          },
          {
            key: 'labelMargin',
            ignore: true
          },
          {
            key: 'columns',
            components: [
              {
                key: 'offset',
                ignore: true
              },
              {
                key: 'push',
                ignore: true
              },
              {
                key: 'pull',
                ignore: true
              }
            ]
          },
          {
            key: 'hideOnChildrenHidden',
            ignore: true
          },
          {
            key: 'description',
            ignore: true
          },
          {
            key: 'autoAdjust',
            ignore: true
          },
          {
            key: 'errorLabel',
            ignore: true
          },
          {
            key: 'customClass',
            ignore: true
          },
          {
            key: 'tabindex',
            ignore: true
          },
          {
            key: 'persistent',
            ignore: true
          },
          {
            key: 'multiple',
            ignore: true
          },
          {
            key: 'clearOnHide',
            ignore: true
          },
          {
            key: 'protected',
            ignore: true
          },
          {
            key: 'hidden',
            ignore: true
          },
          {
            key: 'mask',
            ignore: true
          },
          {
            key: 'disabled',
            ignore: true
          },
          {
            key: 'autofocus',
            ignore: true
          },
          {
            key: 'tableView',
            ignore: true
          },
          {
            key: 'alwaysEnabled',
            ignore: true
          }
        ]
      }
    ],
    panel: [
      {
        key: 'api',
        ignore: true
      },
      {
        key: 'conditional',
        ignore: true
      },
      {
        key: 'logic',
        ignore: true
      },
      {
        key: 'layout',
        ignore: true
      },
      {
        key: 'display',
        components: [
          {
            key: 'labelPosition',
            ignore: true
          },
          {
            key: 'labelWidth',
            ignore: true
          },
          {
            key: 'labelMargin',
            ignore: true
          },
          {
            key: 'theme',
            ignore: true
          },
          {
            key: 'description',
            ignore: true
          },
          {
            key: 'errorLabel',
            ignore: true
          },
          {
            key: 'customClass',
            ignore: true
          },
          {
            key: 'tabindex',
            ignore: true
          },
          {
            key: 'collapsible',
            ignore: true
          },
          {
            key: 'persistent',
            ignore: true
          },
          {
            key: 'multiple',
            ignore: true
          },
          {
            key: 'clearOnHide',
            ignore: true
          },
          {
            key: 'protected',
            ignore: true
          },
          {
            key: 'hidden',
            ignore: true
          },
          {
            key: 'mask',
            ignore: true
          },
          {
            key: 'disabled',
            ignore: true
          },
          {
            key: 'dataGridLabel',
            ignore: true
          },
          {
            key: 'autofocus',
            ignore: true
          },
          {
            key: 'tableView',
            ignore: true
          },
          {
            key: 'alwaysEnabled',
            ignore: true
          }
        ]
      }
    ],
    select: [
      {
        key: 'api',
        ignore: true
      },
      {
        key: 'conditional',
        ignore: true
      },
      {
        key: 'logic',
        ignore: true
      },
      {
        key: 'layout',
        ignore: true
      },
      {
        key: 'display',
        components: [
          {
            key: 'labelPosition',
            ignore: true
          },
          {
            key: 'widget.type',
            ignore: true
          },
          {
            key: 'description',
            ignore: true
          },
          {
            key: 'errorLabel',
            ignore: true
          },
          {
            key: 'mask',
            ignore: true
          },
          {
            key: 'dataGridLabel',
            ignore: true
          },
          {
            key: 'customClass',
            ignore: true
          },
          {
            key: 'tabindex',
            ignore: true
          },
          {
            key: 'persistent',
            ignore: true
          },
          {
            key: 'multiple',
            ignore: true
          },
          {
            key: 'clearOnHide',
            ignore: true
          },
          {
            key: 'protected',
            ignore: true
          },
          {
            key: 'hidden',
            ignore: true
          },
          {
            key: 'mask',
            ignore: true
          },
          {
            key: 'disabled',
            ignore: true
          },
          {
            key: 'autofocus',
            ignore: true
          },
          {
            key: 'tableView',
            ignore: true
          },
          {
            key: 'alwaysEnabled',
            ignore: true
          }
        ]
      },
      {
        key: 'data',
        components: [
          {
            key: 'dataSrc',
            ignore: true
          },
          {
            key: 'template',
            ignore: true
          },
          {
            key: 'searchEnabled',
            ignore: true
          },
          {
            key: 'reference',
            ignore: true
          },
          {
            key: 'readOnlyValue',
            ignore: true
          },
          {
            key: 'customOptions',
            ignore: true
          },
          {
            key: 'selectThreshold',
            ignore: true
          },
          {
            key: 'defaultValue',
            ignore: true
          },
          {
            key: 'refreshOn',
            ignore: true
          },
          {
            key: 'clearOnRefresh',
            ignore: true
          },
          {
            key: 'customDefaultValuePanel',
            ignore: true
          },
          {
            key: 'calculateValuePanel',
            ignore: true
          },
          {
            key: 'allowCalculateOverride',
            ignore: true
          },
          {
            key: 'encrypted',
            ignore: true
          },
          {
            key: 'dbIndex',
            ignore: true
          }
        ]
      },
      {
        key: 'validation',
        components: [
          {
            key: 'unique',
            ignore: true
          },
          {
            key: 'validate.minLength',
            ignore: true
          },
          {
            key: 'validate.maxLength',
            ignore: true
          },
          {
            key: 'validate.minWords',
            ignore: true
          },
          {
            key: 'validate.maxWords',
            ignore: true
          },
          {
            key: 'validate.pattern',
            ignore: true
          },
          {
            key: 'validateOn',
            ignore: true
          },
          {
            key: 'validate.customMessage',
            ignore: true
          },
          {
            key: 'custom-validation-js',
            ignore: true
          },
          {
            key: 'json-validation-json',
            ignore: true
          }
        ]
      }
    ],
    file: [
      {
        key: 'api',
        ignore: true
      },
      {
        key: 'conditional',
        ignore: true
      },
      {
        key: 'data',
        ignore: true
      },
      {
        key: 'logic',
        ignore: true
      },
      {
        key: 'layout',
        ignore: true
      },
      {
        key: 'display',
        components: [
          {
            key: 'labelPosition',
            ignore: true
          },
          {
            key: 'placeholder',
            ignore: true
          },
          {
            key: 'reorder',
            ignore: true
          },
          {
            key: 'description',
            ignore: true
          },
          {
            key: 'errorLabel',
            ignore: true
          },
          {
            key: 'customClass',
            ignore: true
          },
          {
            key: 'tabindex',
            ignore: true
          },
          {
            key: 'persistent',
            ignore: true
          },
          {
            key: 'multiple',
            ignore: true
          },
          {
            key: 'clearOnHide',
            ignore: true
          },
          {
            key: 'protected',
            ignore: true
          },
          {
            key: 'hidden',
            ignore: true
          },
          {
            key: 'mask',
            ignore: true
          },
          {
            key: 'disabled',
            ignore: true
          },
          {
            key: 'autofocus',
            ignore: true
          },
          {
            key: 'tableView',
            ignore: true
          },
          {
            key: 'alwaysEnabled',
            ignore: true
          }
        ]
      },
      {
        key: 'file',
        components: [
          {
            key: 'storage',
            ignore: true
          },
          {
            key: 'url',
            ignore: true
          },
          {
            key: 'options',
            ignore: true
          },
          {
            key: 'fileNameTemplate',
            ignore: true
          },
          {
            key: 'image',
            ignore: true
          },
          {
            key: 'privateDownload',
            ignore: true
          },
          {
            key: 'imageSize',
            ignore: true
          },
          {
            key: 'webcam',
            ignore: true
          },
          {
            key: 'webcamSize',
            ignore: true
          },
          {
            key: 'fileTypes',
            ignore: true
          },
          {
            key: 'filePattern',
            ignore: true
          },
          {
            key: 'fileMinSize',
            ignore: true
          },
          {
            key: 'fileMaxSize',
            ignore: true
          }
        ]
      },
      {
        key: 'validation',
        components: [
          {
            key: 'unique',
            ignore: true
          },
          {
            key: 'validate.pattern',
            ignore: true
          },
          {
            key: 'validateOn',
            ignore: true
          },
          {
            key: 'validate.customMessage',
            ignore: true
          },
          {
            key: 'custom-validation-js',
            ignore: true
          },
          {
            key: 'json-validation-json',
            ignore: true
          }
        ]
      }
    ]
  }
};
