import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Link from 'react-router-dom/es/Link';
import CopyToClipboard from '../../../utilities/copyToClipboard';

function FormCompletion({ url }) {
  return (
    <div className="card-body">
      <h2 className="card-title">Form successfully created!</h2>
      <p className="card-text">
        To finalise setup copy the form url into Umbraco.
      </p>
      <div className="mb-2">
        <CopyToClipboard stringToCopy={url} btnText="Copy Form URL" />
      </div>
      <Link to="/">
        <button type="button" className="btn btn-outline-primary mt-4">
          <i className="fa fa-home pr-2" />
          Go Home
        </button>
      </Link>
    </div>
  );
}

FormCompletion.propTypes = {
  url: PropTypes.string
};

FormCompletion.defaultProps = {
  url: null
};

const mapStateToProps = ({ form: { url } }) => ({
  url: url || null
});

export default connect(mapStateToProps)(FormCompletion);
