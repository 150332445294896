import { Formio } from 'react-formio';
import { push } from 'connected-react-router';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, loginRequest } from '../../../config/auth';

export const USER_REQUEST = 'USER_REQUEST';
export const USER_REQUEST_SUCCESS = 'USER_REQUEST_SUCCESS';
export const USER_REQUEST_FAILURE = 'USER_REQUEST_FAILURE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_SUBMISSION_ACCESS = 'USER_SUBMISSION_ACCESS';
export const USER_FORM_ACCESS = 'USER_FORM_ACCESS';
export const USER_PROJECT_ACCESS = 'USER_PROJECT_ACCESS';
export const USER_ROLES = 'USER_ROLES';

let msalInstance;
let user;

const requestUser = () => ({
  type: USER_REQUEST
});

const receiveUser = user => ({
  type: USER_REQUEST_SUCCESS,
  user
});

const failUser = error => ({
  type: USER_REQUEST_FAILURE,
  error
});

const logoutUser = () => ({
  type: USER_LOGOUT
});

const submissionAccessUser = submissionAccess => ({
  type: USER_SUBMISSION_ACCESS,
  submissionAccess
});

const formAccessUser = formAccess => ({
  type: USER_FORM_ACCESS,
  formAccess
});

const projectAccessUser = projectAccess => ({
  type: USER_FORM_ACCESS,
  projectAccess
});

const rolesUser = roles => ({
  type: USER_ROLES,
  roles
});

function transformSubmissionAccess(forms) {
  return Object.values(forms).reduce(
    (result, form) => ({
      ...result,
      [form.name]: form.submissionAccess.reduce(
        (formSubmissionAccess, access) => ({
          ...formSubmissionAccess,
          [access.type]: access.roles
        }),
        {}
      )
    }),
    {}
  );
}

function transformFormAccess(forms) {
  return Object.values(forms).reduce(
    (result, form) => ({
      ...result,
      [form.name]: form.access.reduce(
        (formAccess, access) => ({
          ...formAccess,
          [access.type]: access.roles
        }),
        {}
      )
    }),
    {}
  );
}

function transformProjectAccess(projectAccess) {
  return projectAccess.reduce(
    (result, access) => ({
      ...result,
      [access.type]: access.roles
    }),
    {}
  );
}

async function signIn(msalInstance) {
  const sessionKey = sessionStorage.getItem('msal.account.keys');
  let login;
  if (sessionKey) {
    const account = msalInstance.getAllAccounts()[0];
    const accessTokenRequest = {
      scopes: ['openid email'],
      account: account
    };
    login = await msalInstance.acquireTokenSilent(accessTokenRequest);
  } else {
    login = await msalInstance.loginPopup(loginRequest);
  }

  return login;
}

async function sso() {
  if (!msalInstance) {
    msalInstance = new PublicClientApplication(msalConfig);
  }
  if (!user) {
    user = 'logging in';
    user = await signIn(msalInstance);
  }
  return user;
}

export const initAuth = () => async dispatch => {
  await sso();
  const projectUrl = Formio.getProjectUrl();
  dispatch(requestUser());
  let currentUser = await Formio.currentUser(Formio);
  if (!currentUser) {
    Formio.logout();
    const formsUser = await Formio.oAuthCurrentUser(Formio, user.accessToken);
    currentUser = formsUser;
  }
  Formio.makeStaticRequest(`${projectUrl}/access`).then(result => {
    const submissionAccess = transformSubmissionAccess(result.forms);
    const formAccess = transformFormAccess(result.forms);
    dispatch(submissionAccessUser(submissionAccess));
    dispatch(formAccessUser(formAccess));
    dispatch(rolesUser(result.roles));
  });
  Formio.makeStaticRequest(projectUrl).then(project => {
    const projectAccess = transformProjectAccess(project.access);
    dispatch(projectAccessUser(projectAccess));
  });
  if (currentUser) {
    dispatch(receiveUser(currentUser));
  } else {
    dispatch(failUser(currentUser));
  }
};

export const logout = () => async dispatch => {
  Formio.logout();
  dispatch(logoutUser());
  dispatch(push('/'));
};
