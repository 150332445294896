const devMsg =
  'There is an error in your form. Please contact the SCA digital team.';

export default {
  firstName: {
    path: 'First Name',
    message: `${devMsg}`
  },
  lastName: {
    path: 'Last Name',
    message: `${devMsg}`
  },
  email: {
    path: 'Email',
    message: `${devMsg}`
  },
  state: {
    path: 'State',
    message: `${devMsg}`
  },
  station: {
    path: 'Station',
    message: `${devMsg}`
  },
  network: {
    path: 'network',
    message: `${devMsg}`
  },
  emailSha256: {
    path: 'emailSha256',
    message: `${devMsg}`
  },
  subscribeSource: {
    path: 'subscribeSource',
    message: `${devMsg}`
  },
  termsCheckbox: {
    path: 'termsCheckbox',
    message: `${devMsg}`
  },
  title: {
    path: 'Title',
    message: 'Please ensure you add a title for the form'
  }
};
