import React from 'react';
import PropTypes from 'prop-types';
import { Link, Route, Switch } from 'react-router-dom';
import UserGuide from './UserGuide';
import DevelopersGuide from './DevelopersGuide';

function Guide({ match: { path } }) {
  const makeActiveLink = e => {
    if (document.querySelector('.btn.active') !== null) {
      document.querySelector('.btn.active').classList.remove('active');
    }
    e.target.classList.add('active');
  };

  return (
    <div className="container mt-5">
      <div className="mb-4">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <Link to={{ pathname: `${path}` }}>
              <button
                className="btn btn-outline-primary active mr-3"
                onClick={e => makeActiveLink(e)}
                type="button"
              >
                For Users
              </button>
            </Link>
          </li>
          <li className="nav-item">
            <Link to={{ pathname: `${path}/developers` }}>
              <button
                className="btn btn-outline-primary"
                onClick={e => makeActiveLink(e)}
                type="button"
              >
                For Developers
              </button>
            </Link>
          </li>
        </ul>
      </div>
      <Switch>
        <Route path={`${path}`} exact component={UserGuide} />
        <Route path={`${path}/developers`} component={DevelopersGuide} />
      </Switch>
    </div>
  );
}

Guide.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired
  }).isRequired
};

export default Guide;
