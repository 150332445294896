import { useContext, useEffect } from 'react';
import { Formio as Formiojs } from 'react-formio';
import get from 'lodash/get';
import { AppContext } from '../AppContext';
import { FORM_CONTEXT } from '../constants/Constants';

const {
  REACT_APP_COMPETITIONS_FORMIO_URL,
  REACT_APP_COMPETITIONS_CONFIGURATION_ID,
  REACT_APP_ARTICLES_FORMIO_URL,
  REACT_APP_ARTICLES_CONFIGURATION_ID,
  REACT_APP_ARTICLES_ENABLE
} = process.env;

const FORM_CONTEXT_KEY = 'FORM_CONTEXT';
let configs = [];

const getProjectPath = formContext => {
  const projectUrl =
    formContext === FORM_CONTEXT.Competition
      ? REACT_APP_COMPETITIONS_FORMIO_URL
      : REACT_APP_ARTICLES_FORMIO_URL;
  const path = `${projectUrl}/form/${
    formContext === FORM_CONTEXT.Competition
      ? REACT_APP_COMPETITIONS_CONFIGURATION_ID
      : REACT_APP_ARTICLES_CONFIGURATION_ID
  }`;
  return { projectUrl, path };
};

const useAppContext = () => {
  const [state, setState] = useContext(AppContext);

  const loadFormConfiguration = async formContext => {
    // retrieve configuration values from form IO
    const config = configs.find(c => c.key === formContext);
    if (config) {
      return config;
    }
    const { path } = getProjectPath(formContext);
    const formio = new Formiojs(path);
    const response = await formio.loadSubmissions({
      params: { select: 'data.key,data.value' }
    });
    const currentConfig = response.reduce((result, item) => {
      const key = get(item, 'data.key');
      result[key] = get(item, 'data.value');
      return result;
    }, {});

    return {
      key: formContext,
      value: currentConfig
    };
  };

  const setFormIOContext = formContext => () => {
    setState(newstate => ({ ...newstate, isLoadingContext: true }));
    const { projectUrl } = getProjectPath(formContext);
    Formiojs.setBaseUrl(projectUrl);
    Formiojs.setBaseUrl(projectUrl);
    setState(newstate => ({
      ...newstate,
      formContext,
      isLoadingContext: false,
      currentConfig: get(
        configs.find(c => c.key === formContext),
        'value'
      )
    }));
    localStorage.setItem(FORM_CONTEXT_KEY, formContext);
  };

  useEffect(() => {
    // restore the last form context in local storage
    const initialFormContext =
      REACT_APP_ARTICLES_ENABLE === 'false'
        ? FORM_CONTEXT.Competition
        : localStorage.getItem(FORM_CONTEXT_KEY) || FORM_CONTEXT.Competition;
    setState(newstate => ({ ...newstate, isLoadingContext: true }));
    const loadConfigs = [];
    Object.values(FORM_CONTEXT).forEach(formContext => {
      loadConfigs.push(loadFormConfiguration(formContext));
    });
    Promise.all(loadConfigs).then(responses => {
      configs = responses;
      setFormIOContext(initialFormContext)();
    });
  }, []);

  return { setFormIOContext, state };
};

export { useAppContext, getProjectPath };
