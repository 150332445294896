import { toast } from 'react-toastify';
import { Formio as Formiojs } from 'react-formio';
import validateFormBuilder from '../../../utilities/validations/validateFormBuilder';
import formioValidation from '../../../utilities/validations/formioValidation';
import { clearTags } from '../FormTags';
import { selectForm } from '../../selectors/Form';

export const FORM_CLEAR_ERROR = 'FORM_CLEAR_ERROR';
export const FORM_REQUEST = 'FORM_REQUEST';
export const FORM_SUCCESS = 'FORM_SUCCESS';
export const FORM_FAILURE = 'FORM_FAILURE';
export const FORM_SAVE = 'FORM_SAVE';
export const FORM_RESET = 'FORM_RESET';

export const clearFormError = name => ({
  type: FORM_CLEAR_ERROR,
  name
});

export const requestForm = (name, id, url) => ({
  type: FORM_REQUEST,
  name,
  id,
  url
});

export const receiveForm = (name, form, url) => ({
  type: FORM_SUCCESS,
  form,
  name,
  url
});

export const failForm = (name, err) => ({
  type: FORM_FAILURE,
  error: err,
  name
});

export const resetForm = name => ({
  type: FORM_RESET,
  name
});

export const sendForm = (name, form) => ({
  type: FORM_SAVE,
  form,
  name
});

export const getForm = (name, id = '', done = () => {}) => (
  dispatch,
  getState
) => {
  // Check to see if the form is already loaded.
  const form = selectForm(name, getState());
  if (
    form.components &&
    Array.isArray(form.components) &&
    form.components.length &&
    form._id === id
  ) {
    return;
  }

  const path = `${Formiojs.getProjectUrl()}${id ? `/form/${id}` : name}`;
  const formio = new Formiojs(path);

  dispatch(requestForm(name, id, path));

  formio
    .loadForm()
    .then(result => {
      dispatch(receiveForm(name, result));
      done(null, result);
    })
    .catch(result => {
      dispatch(failForm(name, result));
      done(result);
    });
};

export const saveForm = (
  name,
  form,
  validatorKeys,
  done = () => {}
) => dispatch => {
  // Check for validation errors
  const hasErrors = validateFormBuilder(form, validatorKeys);
  if (hasErrors) {
    dispatch(failForm(name, hasErrors));
    toast.error('Your form has errors');
    return;
  }

  dispatch(sendForm(name, form));
  const id = form._id;
  const path = `${Formiojs.getProjectUrl()}/form${id ? `/${id}` : ''}`;
  const formio = new Formiojs(path);

  formio
    .saveForm(form)
    .then(result => {
      const url = `${Formiojs.getProjectUrl()}/form/${result._id}`;
      dispatch(clearTags());
      dispatch(receiveForm(name, result, url));
      done(null, result);
    })
    .catch(result => {
      console.log(result);
      if (result.errors) {
        const errorText = formioValidation(result.errors);
        toast.error(`${errorText}`);
      }
      dispatch(failForm(name, result));
      done(result);
    });
};

export const deleteForm = (name, id, done = () => {}) => dispatch => {
  const path = `${Formiojs.getProjectUrl()}/form/${id}`;
  const formio = new Formiojs(path);

  return formio
    .deleteForm()
    .then(() => {
      dispatch(resetForm(name));
      done();
    })
    .catch(result => {
      dispatch(failForm(name, result));
      done(result);
    });
};
