import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { push, replace } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { indexForms, selectRoot, selectError, Errors } from 'react-formio';
import {
  useAppContext,
  getProjectPath
} from '../../../../../hooks/useAppContext';
import FormGrid from '../../../../../components/FormGrid';
import Loading from '../../../../../components/Loading';

function FormTable({ searchQuery, location, limit, handleLimit }) {
  const {
    state: { currentConfig, formContext }
  } = useAppContext();
  const { projectUrl } = getProjectPath(formContext);
  const dispatch = useDispatch();
  const { email, forms, errors } = useSelector(state => ({
    email: (state.auth.user && state.auth.user.data.email) || null,
    forms: selectRoot('forms', state),
    errors: selectError('forms', state)
  }));
  const setTags = [`${email}`];
  const tags = get(location, 'state.tags', null);
  const [query, setQuery] = useState({});

  const initialQuery = {
    type: 'form',
    tags__in: tags || setTags,
    limit,
    sort: '-created'
  };

  const getForms = (page, queryParam) => {
    dispatch(indexForms('forms', page, { ...initialQuery, ...queryParam }));
    setQuery(queryParam);
  };

  const onAction = (form, action) => {
    switch (action) {
      case 'submission':
        dispatch(push(`/form/${form._id}/submission`));
        break;
      case 'edit':
        dispatch(push(`/form/${form._id}/edit`));
        break;
      default:
    }
  };

  useEffect(() => {
    if (setTags) {
      getForms(1);
    } else if (tags) {
      getForms(1);
      // clear history state
      replace({ pathname: '/form/view', state: {} });
    } else {
      // eslint-disable-next-line no-console
      console.log('FormList - No tags detected');
    }
  }, [currentConfig, tags]);

  useEffect(() => {
    getForms(1, { name__regex: `/${searchQuery}/` });
  }, [searchQuery]);

  useEffect(() => {
    getForms(1);
  }, [limit]);

  const onPage = page => {
    getForms(page, query);
  };

  const onPageSizeChanged = pageSize => {
    handleLimit(pageSize);
  };

  if (forms.isActive) {
    return <Loading />;
  }

  return (
    <div>
      <Errors errors={errors} />
      {forms && (
        <FormGrid
          {...{
            forms,
            onAction,
            getForms,
            onPage,
            onPageSizeChanged,
            query,
            projectUrl
          }}
        />
      )}
    </div>
  );
}

FormTable.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
  limit: PropTypes.number,
  handleLimit: PropTypes.func.isRequired
};

FormTable.defaultProps = {
  limit: 25,
  location: undefined
};

export default FormTable;
