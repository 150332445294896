import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  selectRoot,
  Form,
  selectError,
  Errors,
  getSubmission
} from 'react-formio';
import Loading from '../../../../components/Loading';
import PageTitle from '../../../../components/PageTitle';
import { getForm } from '../../../../store/actions/Form';

function SubmissionItem({
  match,
  options,
  errors,
  form,
  retrieveSubmission,
  retrieveForm,
  submission: { submission, isActive: isSubActive, url }
}) {
  useEffect(() => {
    retrieveForm(match.params.formId);
    retrieveSubmission(match.params.submissionId);
  }, []);

  if (isSubActive) {
    return <Loading />;
  }

  return (
    <div>
      {form && (
        <>
          <PageTitle title={`One submission result for: ${form.title}`} />
          <Errors errors={errors} />
          <Form
            form={form}
            submission={submission}
            url={url}
            options={options}
          />
        </>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  const { form } = state.form;
  return {
    form,
    submission: selectRoot('submission', state),
    options: {
      readOnly: true
    },
    errors: [selectError('submission', state), selectError('form', state)]
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  retrieveSubmission: id =>
    dispatch(getSubmission('submission', id, ownProps.match.params.formId)),
  retrieveForm: id => dispatch(getForm('form', id))
});

SubmissionItem.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.string)
  }).isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.arrayOf(PropTypes.string, PropTypes.object).isRequired,
  submission: PropTypes.shape({
    submission: PropTypes.objectOf(PropTypes.any),
    isActive: PropTypes.bool,
    url: PropTypes.string
  }).isRequired,
  retrieveSubmission: PropTypes.func.isRequired,
  retrieveForm: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionItem);
