import React from 'react';
import { Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './page/Home';
import Form from './page/Form';
import Guide from './page/Guide';
import { AppProvider } from './AppContext';

const App = () => (
  <AppProvider>
    <div>
      <Header />
      <div id="main">
        <Route exact path="/" component={Home} />
        <Route path="/form" component={Form} />
        <Route path="/guide" component={Guide} />
      </div>
    </div>
  </AppProvider>
);

export default App;
