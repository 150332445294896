import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

function NotFound({ goHome }) {
  return (
    <>
      <div className="mt-5">
        <h2>Oops looks you arrive here by accident!</h2>
        <button
          type="button"
          className="btn btn-outline-primary mt-5"
          onClick={goHome}
        >
          <i className="fa fa-chevron-left mr-2" />
          Back home
        </button>
      </div>
      <hr />
    </>
  );
}

const mapDispatchToProps = dispatch => ({
  goHome: () => dispatch(push('/'))
});

NotFound.propTypes = {
  goHome: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(NotFound);
