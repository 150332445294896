import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PageTitle from '../../../components/PageTitle';
import FormTable from './components/FormTable';

function FormList({ title, backBtn, location }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [limit, setLimit] = useState(25);

  const handleSearch = e => {
    setSearchQuery(e.target.value);
  };

  const handleLimit = newLimit => {
    setLimit(newLimit);
  };

  return (
    <div>
      <PageTitle title={title || 'Form Results'} backBtn={backBtn} />
      <div className="input-group input-group-lg my-5">
        <div className="input-group-prepend">
          <span className="input-group-text" id="inputGroup-sizing-lg">
            Search by form name
          </span>
        </div>
        <input
          type="text"
          className="form-control"
          onChange={e => {
            handleSearch(e);
          }}
        />
      </div>
      <FormTable
        searchQuery={searchQuery}
        limit={limit}
        handleLimit={handleLimit}
        location={location}
      />
    </div>
  );
}

FormList.propTypes = {
  backBtn: PropTypes.bool,
  title: PropTypes.string,
  location: PropTypes.objectOf(PropTypes.any)
};

FormList.defaultProps = {
  backBtn: true,
  title: '',
  location: undefined
};

export default FormList;
