import { Utils as FormioUtils } from 'react-formio';

const {
  REACT_APP_NETWORK_CHECKBOX_PANEL_KEY,
  REACT_APP_HIT_NETWORK_CHECKBOX_PANEL_KEY,
  REACT_APP_MMM_NETWORK_CHECKBOX_PANEL_KEY,
  REACT_APP_OWNER_KEY
} = process.env;

function returnKeyWhenTrue(obj) {
  return Object.keys(obj).filter(item => obj[item]);
}

// Return all valid tags from submission object
const filterTags = data => {
  let validTags = [];

  // Until function needs data to be in a submission object.
  const submission = { data };

  const networkValue = FormioUtils.getValue(
    submission,
    `${REACT_APP_NETWORK_CHECKBOX_PANEL_KEY}`
  );
  if (networkValue) {
    const tags = returnKeyWhenTrue(networkValue);
    validTags = validTags.concat(tags);
  }
  const hitValues = FormioUtils.getValue(
    submission,
    REACT_APP_HIT_NETWORK_CHECKBOX_PANEL_KEY
  );
  if (hitValues) {
    const tags = returnKeyWhenTrue(hitValues);
    validTags = validTags.concat(tags);
  }
  const mmmValues = FormioUtils.getValue(
    submission,
    REACT_APP_MMM_NETWORK_CHECKBOX_PANEL_KEY
  );
  if (mmmValues) {
    const tags = returnKeyWhenTrue(mmmValues);
    validTags = validTags.concat(tags);
  }

  const owner = FormioUtils.getValue(submission, REACT_APP_OWNER_KEY);
  if (owner) {
    validTags.push(owner);
  }

  return validTags;
};

export default filterTags;
