const FORM_CONTEXT = {
  Competition: 'competition',
  Article: 'article'
};

const ENABLE_CONTEXT_SWITCHING_ROUTES_NAMES = [
  '/',
  '/guide',
  '/form/tags',
  '/form/template',
  '/form/find'
];

export { FORM_CONTEXT, ENABLE_CONTEXT_SWITCHING_ROUTES_NAMES };
