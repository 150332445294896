import React, { useEffect } from 'react';
import get from 'lodash/get';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-formio';
import { selectBuilderTemplate } from '../../../store/selectors/FormTemplate';
import {
  getFormTemplate,
  setBuilderTemplate
} from '../../../store/actions/FormTemplate';
import PageTitle from '../../../components/PageTitle';
import NotFound from '../../../components/NotFound';
import { useAppContext } from '../../../hooks/useAppContext';

const SelectTemplate = () => {
  const {
    state: { currentConfig }
  } = useAppContext();
  const builderTemplateSelectorId = get(
    currentConfig,
    'BUILDER_TEMPLATE_SELECTOR_ID',
    null
  );

  const { tags, form } = useSelector(state => ({
    tags: get(state, 'tags.tags'),
    form: builderTemplateSelectorId
      ? selectBuilderTemplate(builderTemplateSelectorId, state)
      : null
  }));
  const dispatch = useDispatch();
  useEffect(() => {
    if (builderTemplateSelectorId) {
      dispatch(getFormTemplate(builderTemplateSelectorId));
    }
  }, [builderTemplateSelectorId]);

  const setTemplate = ({ data: { builderType } }) => {
    if (builderType) dispatch(setBuilderTemplate(builderType));
    dispatch(push('/form/create'));
  };

  if (form && tags.length <= 0) {
    return <NotFound />;
  }

  return (
    <div>
      <PageTitle title="Select a form builder template" />
      {form && (
        <Form
          form={form}
          onCustomEvent={submission => setTemplate(submission)}
        />
      )}
    </div>
  );
};

export default SelectTemplate;
