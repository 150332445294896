import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isFunction from 'lodash/isFunction';
import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import {
  Grid,
  Utils as FormioUtils,
  Components,
  Columns,
  Operations,
  PageSizes,
  defaultPageSizes,
  stopPropagationWrapper
} from 'react-formio';
import { formatDateTime } from '../../utilities/formatDate';

export default class SubmissionGrid extends React.Component {
  static propTypes = {
    columns: Columns,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    getSubmissions: PropTypes.func,
    onAction: PropTypes.func,
    onPageSizeChanged: PropTypes.func,
    operations: Operations,
    pageSizes: PageSizes,
    submissions: PropTypes.objectOf(PropTypes.any).isRequired
  };

  static defaultProps = {
    columns: [],
    getSubmissions: () => {},
    onAction: () => {},
    onPageSizeChanged: () => {},
    operations: [
      {
        action: 'view',
        buttonType: 'warning',
        icon: 'list-alt',
        permissionsResolver() {
          return true;
        },
        title: 'View'
      },
      {
        action: 'edit',
        buttonType: 'secondary',
        icon: 'edit',
        permissionsResolver() {
          return true;
        },
        title: 'Edit'
      },
      {
        action: 'delete',
        buttonType: 'danger',
        icon: 'trash',
        permissionsResolver() {
          return true;
        }
      }
    ],
    pageSizes: defaultPageSizes
  };

  onSort = ({ key, sort }) => {
    if (_isFunction(sort)) {
      return sort();
    }

    const {
      getSubmissions,
      submissions: { sort: currentSort }
    } = this.props;

    const sortKey = _isString(sort) ? sort : key;
    const ascSort = sortKey;
    const descSort = `-${sortKey}`;
    const noSort = '';

    let nextSort = noSort;
    if (currentSort === ascSort) {
      nextSort = descSort;
    } else if (currentSort === descSort) {
      nextSort = noSort;
    } else {
      nextSort = ascSort;
    }

    return getSubmissions(1, {
      sort: nextSort
    });
  };

  getColumns = () => {
    const columns = [];
    // eslint-disable-next-line consistent-return
    FormioUtils.eachComponent(this.props.form.components, component => {
      // ignore all components except name and email
      if (
        component.key !== 'firstName' &&
        component.key !== 'lastName' &&
        component.key !== 'email' &&
        component.key !== 'created'
      ) {
        return null;
      }

      if (component.input && component.tableView && component.key) {
        columns.push({
          key: `data.${component.key}`,
          title: component.label || component.title || component.key,
          sort: true,
          component: Components.create(component, null, null, true)
        });
      }
    });

    columns.push({
      key: 'created',
      title: 'Submitted on',
      sort: false
    });

    columns.push({
      key: 'operations',
      title: 'Details',
      sort: false
    });

    return columns;
  };

  Cell = ({ row: submission, column }) => {
    const { form, onAction, operations } = this.props;

    if (column.key === 'created') {
      const cellValue = _get(submission, column.key);
      return (
        <span className="d-inline-block text-truncate text-center">
          {formatDateTime(cellValue)}
        </span>
      );
    }
    if (column.key === 'operations') {
      return (
        <div>
          {operations.map(
            ({
              action,
              buttonType = 'primary',
              icon = '',
              permissionsResolver = () => true,
              title = ''
            }) =>
              permissionsResolver(form, submission) ? (
                <button
                  type="button"
                  className={`btn btn-${buttonType} btn-sm form-btn`}
                  onClick={stopPropagationWrapper(() =>
                    onAction(submission, action)
                  )}
                  key={action}
                >
                  {icon ? (
                    <span>
                      <i className={`fa fa-${icon}`} />
                      &nbsp;
                    </span>
                  ) : null}
                  {title}
                </button>
              ) : null
          )}
        </div>
      );
    }

    const value = _isFunction(column.value)
      ? column.value(submission)
      : _get(submission, column.key, '');

    if (_isObject(value) && value.content) {
      if (value.isHtml) {
        // eslint-disable-next-line react/no-danger
        return <div dangerouslySetInnerHTML={{ __html: value.content }} />;
      }
      return <span>{String(value.content)}</span>;
    }
    return <span>{String(value)}</span>;
  };

  render = () => {
    const {
      columns: propColumns,
      form,
      getSubmissions,
      onAction,
      onPageSizeChanged,
      pageSizes,
      submissions: {
        limit,
        pagination: { page, numPages, total },
        sort,
        submissions
      }
    } = this.props;

    const columns = propColumns.length ? propColumns : this.getColumns(form);
    const skip = (page - 1) * limit;
    const last = Math.min(skip + limit, total);

    return (
      <Grid
        Cell={this.Cell}
        activePage={page}
        columns={columns}
        emptyText="No data found"
        firstItem={skip + 1}
        items={submissions}
        lastItem={last}
        onAction={onAction}
        onPage={getSubmissions}
        onPageSizeChanged={onPageSizeChanged}
        onSort={this.onSort}
        pageSize={limit}
        pageSizes={pageSizes}
        pages={numPages}
        sortOrder={sort}
        total={total}
      />
    );
  };
}
