const { REACT_APP_MARKETING_CLOUD_URL } = process.env;

const marketingCloudScript = () => {
  return {
    data: {
      handler: ['after'],
      method: ['create', 'update'],
      name: 'webhook',
      title: 'Send Customer Marketing Data',
      settings: {
        url: REACT_APP_MARKETING_CLOUD_URL,
        method: 'post'
      }
    }
  };
};

export default marketingCloudScript;
