import { Route, Switch } from 'react-router-dom';
import React from 'react';
import Submission from './Submission';
import CreateForm from './CreateForm';
import CreateTags from './CreateTags';
import SelectTemplate from './SelectTemplate';
import FindForm from './FindForm';
import FormList from './FormList';
import EditForm from './EditForm';
import FormCompletion from './FormCompletion';

const Form = () => (
  <div className="container mt-5">
    <Switch>
      <Route path="/form/tags" component={CreateTags} />
      <Route exact path="/form/template" component={SelectTemplate} />
      <Route exact path="/form/create" component={CreateForm} />
      <Route path="/form/find" component={FindForm} />
      <Route path="/form/view" component={FormList} />
      <Route path="/form/:formId/edit" component={EditForm} />
      <Route path="/form/finish" component={FormCompletion} />
      <Route path="/form/:formId/submission" component={Submission} />
    </Switch>
  </div>
);

export default Form;
