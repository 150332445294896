import { Utils as FormioUtils } from 'react-formio';
import isEmpty from 'lodash/isEmpty';
import message from './errorMessages';

export default function validateFormBuilder(form, validatorKeys) {
  const errors = {};
  if (isEmpty(form.title)) {
    Object.assign(errors, { title: message.title });
  }
  const { components } = form;
  validatorKeys.forEach(item => {
    if (!FormioUtils.getComponent(components, item)) {
      Object.assign(errors, { [item]: message[item] });
    }
  });
  if (Object.keys(errors).length > 0) return { errors };
  return false;
}
