import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const AppContext = createContext([{}, () => {}]);

const AppProvider = props => {
  const [state, setState] = useState({ isLoadingContext: null });
  return (
    <AppContext.Provider value={[state, setState]}>
      {props.children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { AppContext, AppProvider };
