import { Formio as Formiojs } from 'react-formio';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import marketingCloudScript from '../../../constants/marketingCloud';

export const ACTION_SUCCESS = 'ACTION_SUCCESS';
export const ACTION_FAILURE = 'ACTION_FAILURE';
export const ACTION_RESET = 'ACTION_RESET';

export const failAction = (name, err) => ({
  type: ACTION_FAILURE,
  error: err,
  name
});

export const receiveAction = (name, form, url) => ({
  type: ACTION_SUCCESS,
  form,
  name,
  url
});

export const sendAction = (
  formid,
  done = () => {}
) => dispatch => {
  const path = `${Formiojs.getProjectUrl()}/form${formid ? `/${formid}` : ''}`;
  const formio = new Formiojs(path);

  const marketingCloud = marketingCloudScript();
  const marketingCloudAction = formio.saveAction(marketingCloud);

  Promise.all([marketingCloudAction])
    .then(result => {
      dispatch(push('/form/finish'));
      toast.success('Marketing cloud details saved');
      dispatch(receiveAction('form', result));
      done(null, result);
    })
    .catch(err => {
      dispatch(failAction('form', err));
      done(err);
    });
};
