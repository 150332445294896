import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/scss/main.scss';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './store';
import App from './App';
import './styles.scss';

// Initialise react toast
toast.configure();

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div>
        <App />
      </div>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
