import React, { useEffect, useRef } from 'react';
import { Errors, selectError } from 'react-formio';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { toast } from 'react-toastify';
import FormEdit from '../../../components/FormEdit';
import { clearFormError, saveForm } from '../../../store/actions/Form';
import buildOptions from '../../../constants/formBuilder';
import {
  selectBuilderTemplate,
  selectBuilderTemplateError
} from '../../../store/selectors/FormTemplate';
import { getFormTemplate } from '../../../store/actions/FormTemplate';
import { sendAction } from '../../../store/actions/FormAction';
import NotFound from '../../../components/NotFound';
import Loading from '../../../components/Loading';
import { initAuth } from '../../../store/actions/Auth';

import { FORM_CONTEXT } from '../../../constants/Constants';
import { useAppContext } from '../../../hooks/useAppContext';

const CreateForm = () => {
  const {
    state: { formContext, currentConfig }
  } = useAppContext();

  const { tags, errors, builderType, form, templateErrors } = useSelector(
    state => ({
      tags: get(state, 'tags.tags'),
      errors: selectError('forms', state),
      builderType: get(state, 'formTemplate.builderType'),
      form: selectBuilderTemplate(
        get(state, 'formTemplate.builderType'),
        state
      ),
      templateErrors: selectBuilderTemplateError(
        get(state, 'formTemplate.builderType'),
        state
      )
    })
  );

  const dispatch = useDispatch();
  const validatorKeys = get(currentConfig, 'BUILDER_VALIDATOR_KEYS', '').split(
    '|'
  );

  const errorRef = useRef(null);
  const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop);

  useEffect(() => {
    dispatch(getFormTemplate(builderType));
    return () => dispatch(clearFormError('form'));
  }, []);

  useEffect(() => {
    if (!isEmpty(errors)) {
      scrollToRef(errorRef);
    }
  }, [errors]);

  const createForm = createform => {
    const newForm = {
      ...createform,
      tags: [formContext, ...tags],
      created: new Date().toString()
    };
    dispatch(
      saveForm('form', newForm, validatorKeys, (err, saveform) => {
        if (!err) {
          toast.success('Form created');
          if (formContext === FORM_CONTEXT.Competition) {
            // only need to create action for competition, not article
            dispatch(sendAction(saveform._id));
          } else {
            dispatch(push('/form/finish'));
          }
        } else {
          toast.error(
            'Something went wrong submitting the form. Please try again.'
          );
          dispatch(initAuth());
        }
      })
    );
  };

  if (!form) {
    return <Loading />;
  }

  if (templateErrors) {
    return <NotFound />;
  }

  return (
    <div>
      <h2>Create a new Form</h2>
      <hr />
      <Errors errors={errors} ref={errorRef} />
      {form && (
        <FormEdit
          saveForm={createForm}
          saveText="Create Form"
          form={form}
          options={buildOptions}
        />
      )}
    </div>
  );
};
export default CreateForm;
