import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectForm, Errors, selectError } from 'react-formio';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import FormEdit from '../../../components/FormEdit';
import { clearFormError, getForm, saveForm } from '../../../store/actions/Form';
import buildOptions from '../../../constants/formBuilder';
import PageTitle from '../../../components/PageTitle';
import NotFound from '../../../components/NotFound';
import Loading from '../../../components/Loading';
import { useAppContext } from '../../../hooks/useAppContext';

const EditForm = ({ match }) => {
  const {
    state: { currentConfig }
  } = useAppContext();

  const { errors, form, isLoading } = useSelector(state => ({
    errors: selectError('form', state),
    form: selectForm('form', state),
    isLoading: state.form.isActive
  }));
  const dispatch = useDispatch();
  const validatorKeys = get(currentConfig, 'BUILDER_VALIDATOR_KEYS', '').split(
    '|'
  );

  const errorRef = useRef(null);
  const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop);

  const retrieveForm = id => {
    dispatch(getForm('form', id));
  };

  const saveFormFunc = saveform => {
    dispatch(
      saveForm('form', saveform, validatorKeys, err => {
        if (!err) {
          toast.success('Form updated!');
        }
      })
    );
  };

  const clearErrors = name => {
    dispatch(clearFormError(name));
  };

  useEffect(() => {
    retrieveForm(match.params.formId);
    return () => clearErrors('form');
  }, []);

  useEffect(() => {
    if (!isEmpty(form) && !isEmpty(errors)) {
      scrollToRef(errorRef);
    }
  }, [errors]);

  if (isLoading) {
    return <Loading />;
  }

  if (isEmpty(form) && !isLoading) {
    return <NotFound />;
  }

  return (
    <div>
      <PageTitle title={`Edit ${form.title} Form`} />
      <Errors errors={errors} ref={errorRef} />
      <FormEdit
        saveForm={saveFormFunc}
        saveText="Save Form"
        form={form}
        options={buildOptions}
      />
    </div>
  );
};

EditForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.string)
  }).isRequired
};

export default EditForm;
