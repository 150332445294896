import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Errors, selectError } from 'react-formio';
import PageTitle from '../../../../components/PageTitle';
import NotFound from '../../../../components/NotFound';
import SubmissionTable from './components/SubmissionTable';

function SubmissionList({ match: { params } }) {
  const [searchEmail, setSearchEmail] = useState('');
  const [limit, setLimit] = useState(25);
  const { form, errors } = useSelector(state => {
    const getForm = state.forms.forms.find(item => item._id === params.formId);
    return {
      form: getForm,
      errors: selectError('forms', state)
    };
  });

  const handleSearch = e => {
    setSearchEmail(e.target.value);
  };

  const handleLimit = changeLimit => {
    setLimit(changeLimit);
  };

  if (!form) {
    return <NotFound />;
  }

  return (
    <>
      <div className="form-index">
        <PageTitle title={`Submission Results for: ${form.title}`} />
        <Errors errors={errors} />
        <div className="input-group input-group-lg my-5">
          <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroup-sizing-lg">
              Search by email
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            onChange={e => {
              handleSearch(e);
            }}
          />
        </div>
        <SubmissionTable
          form={form}
          params={params}
          searchEmail={searchEmail}
          limit={limit}
          handleLimit={handleLimit}
        />
      </div>
    </>
  );
}

SubmissionList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.any)
  }).isRequired
};

export default SubmissionList;
