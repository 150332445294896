const {
  REACT_APP_AZURE_URL,
  REACT_APP_AZURE_CLIENT_ID,
  REACT_APP_AZURE_REDIRECT_URL
} = process.env;

export const msalConfig = {
  auth: {
    clientId: REACT_APP_AZURE_CLIENT_ID,
    authority: REACT_APP_AZURE_URL,
    redirectUri: REACT_APP_AZURE_REDIRECT_URL,
    postLogoutRedirectUri: '/'
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
};

export const loginRequest = {
  scopes: ['openid email']
};

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com'
};
