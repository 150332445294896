import { toast } from 'react-toastify';
import filterTags from '../../../utilities/tagFilter';
import validateFormTags from '../../../utilities/validations/validateFormTags';

export const ADD_FORM_TAGS = 'ADD_FORM_TAGS';
export const CLEAR_FORM_TAGS = 'CLEAR_FORM_TAGS';

export const addTags = tags => ({
  type: ADD_FORM_TAGS,
  tags
});

export const clearTags = () => ({
  type: CLEAR_FORM_TAGS
});

export const addFormTags = data => dispatch => {
  const tags = filterTags(data);
  const hasErrors = validateFormTags(tags);
  if (hasErrors) {
    toast.error('Please add at least one tag');
    return false;
  }
  dispatch(addTags(tags));
  return true;
};
