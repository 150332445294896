import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { toast } from 'react-toastify';
import getComponentLabels from '../../../../../../utilities/getComponentLabels';
import formatSubmissionForCSV from '../../../../../../utilities/formatSubmissionForCSV';
import getAllSubmissions from '../../../../../../store/actions/Submissions';

function DownloadCSV({
  formId,
  components,
  title,
  searchEmail,
  submissions: { submissions }
}) {
  const labels = getComponentLabels(components);
  const filename = `${title}-submissions`.replace(/\s+/g, '-').toLowerCase();

  const [allSubmissions, setSubmissionStatus] = useState([]);
  const csvLink = useRef(null);

  async function fetchAllSubmissions() {
    const result = await getAllSubmissions(formId, searchEmail);
    if (result.length > 1) {
      result.sort((a, b) => new Date(b.modified) - new Date(a.modified));
    }
    const formattedSubmissions = formatSubmissionForCSV(result);
    setSubmissionStatus(formattedSubmissions);
  }

  useEffect(() => {
    if (allSubmissions.length > 0 && csvLink.current) {
      csvLink.current.link.click();
      toast.success('File downloaded');
    }
  }, [allSubmissions]);

  return (
    <div>
      {submissions.length > 0 && (
        <>
          <button
            className="btn btn-primary mb-4"
            onClick={() => fetchAllSubmissions()}
            type="button"
          >
            Download data as a CSV file
          </button>
          <CSVLink
            data={allSubmissions}
            headers={labels}
            filename={`${filename}.csv`}
            className="d-block"
            ref={csvLink}
            target="_blank"
          />
        </>
      )}
    </div>
  );
}

DownloadCSV.propTypes = {
  title: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  searchEmail: PropTypes.string.isRequired,
  submissions: PropTypes.objectOf(PropTypes.any).isRequired,
  components: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default DownloadCSV;
