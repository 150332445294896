import * as types from '../../actions/FormAction';

export default function formAction(config) {
  const initialState = {};

  return (state = initialState, action) => {
    // Only proceed for this form.
    if (action.name !== config.name) {
      return state;
    }
    switch (action.type) {
      case types.ACTION_SUCCESS:
        return {
          ...state,
          ...action
        };
      case types.ACTION_FAILURE:
        return {
          ...state,
          ...action
        };
      case types.ACTION_RESET:
        return initialState;
      default:
        return state;
    }
  };
}
