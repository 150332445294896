import PropTypes from 'prop-types';
import React from 'react';
import {
  defaultPageSizes,
  Columns,
  PageSizes,
  stopPropagationWrapper,
  Grid
} from 'react-formio';
import _get from 'lodash/get';
import _isFunction from 'lodash/isFunction';
import _isString from 'lodash/isString';
import { formatDate } from '../../utilities/formatDate';
import CopyToClipboard from '../../utilities/copyToClipboard';

export default class FormGrid extends React.Component {
  static propTypes = {
    columns: Columns,
    formAccess: PropTypes.func,
    forms: PropTypes.objectOf(PropTypes.any).isRequired,
    getForms: PropTypes.func,
    onAction: PropTypes.func.isRequired,
    onPageSizeChanged: PropTypes.func,
    pageSizes: PageSizes,
    projectUrl: PropTypes.string.isRequired
  };

  static defaultProps = {
    columns: [
      {
        key: 'title',
        title: 'Form Name',
        sort: false,
        width: 6
      },
      {
        key: 'created',
        title: 'Created',
        sort: false,
        width: 2
      },
      {
        key: 'results',
        title: 'Results',
        sort: false,
        width: 1
      },
      {
        key: 'edit',
        title: 'Edit',
        sort: false,
        width: 1
      },
      {
        key: 'url',
        title: 'Form Url',
        sort: false,
        width: 2
      }
    ],
    formAccess: () => ({
      form: {
        create: false,
        view: false,
        edit: true,
        delete: false
      },
      submission: {
        create: false,
        view: true,
        edit: false,
        delete: false
      }
    }),
    getForms: () => {},
    onPageSizeChanged: () => {},
    pageSizes: defaultPageSizes
  };

  onSort = ({ key, sort }) => {
    if (_isFunction(sort)) {
      return sort();
    }

    const {
      forms: { sort: currentSort },
      getForms
    } = this.props;

    const sortKey = _isString(sort) ? sort : key;
    const ascSort = sortKey;
    const descSort = `-${sortKey}`;
    const noSort = '';

    let nextSort = noSort;
    if (currentSort === ascSort) {
      nextSort = descSort;
    } else if (currentSort === descSort) {
      nextSort = noSort;
    } else {
      nextSort = ascSort;
    }

    return getForms(1, {
      sort: nextSort
    });
  };

  Cell = ({ row: form, column }) => {
    const { formAccess, onAction, projectUrl } = this.props;

    const access = formAccess(form);

    if (column.key === 'title') {
      return (
        <span>
          <h5 className="font-weight-bold">{form.title}</h5>
        </span>
      );
    }
    if (column.key === 'created') {
      return (
        <span>
          <h5>{formatDate(form.created)}</h5>
        </span>
      );
    }
    if (column.key === 'url') {
      return (
        <span>
          <CopyToClipboard
            stringToCopy={`${projectUrl}/form/${form._id}`}
            btnText="Copy Form URL"
          />
        </span>
      );
    }
    if (column.key === 'edit') {
      return (
        <span>
          {access.form.edit ? (
            <button
              type="button"
              className="btn btn-dark form-btn btn-block"
              onClick={stopPropagationWrapper(() => onAction(form, 'edit'))}
            >
              Edit
            </button>
          ) : null}
        </span>
      );
    }
    if (column.key === 'results') {
      return (
        <span>
          {access.submission.view ? (
            <button
              type="button"
              className="btn btn-dark form-btn btn-block"
              onClick={stopPropagationWrapper(() =>
                onAction(form, 'submission')
              )}
            >
              View
            </button>
          ) : null}
        </span>
      );
    }
    return (
      <span>
        {_isFunction(column.value)
          ? column.value(form)
          : _get(form, column.key, '')}
      </span>
    );
  };

  render() {
    const {
      columns,
      forms: {
        forms,
        limit,
        pagination: { page, numPages, total },
        sort
      },
      getForms,
      onAction,
      onPageSizeChanged,
      pageSizes
    } = this.props;

    const skip = (page - 1) * limit;
    const last = Math.min(skip + limit, total);

    return (
      <Grid
        Cell={this.Cell}
        activePage={page}
        columns={columns}
        emptyText="No forms found"
        firstItem={skip + 1}
        items={forms}
        lastItem={last}
        onAction={onAction}
        onPage={getForms}
        onPageSizeChanged={onPageSizeChanged}
        onSort={this.onSort}
        pageSize={limit}
        pageSizes={pageSizes}
        pages={numPages}
        sortOrder={sort}
        total={total}
      />
    );
  }
}
