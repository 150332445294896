export const selectTemplate = (id, state) => state.formTemplate.templates[id];

export const selectBuilderTemplate = (id, state) => {
  const form = selectTemplate(id, state);

  if (form) {
    return {
      type: form.type,
      tags: form.tags,
      components: form.components,
      submissionAccess: form.submissionAccess,
      access: form.access
    };
  }
  return null;
};

export const selectBuilderTemplateError = (id, state) => {
  const form = selectTemplate(id, state);
  if (form) {
    return form.error;
  }
  return null;
};
