import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { initAuth, logout } from '../store/actions/Auth';
import Logo from '../icons/scaLogo.svg';
import NavLink from './NavLink';
import { useAppContext } from '../hooks/useAppContext';
import {
  FORM_CONTEXT,
  ENABLE_CONTEXT_SWITCHING_ROUTES_NAMES
} from '../constants/Constants';

const { REACT_APP_ARTICLES_ENABLE } = process.env;

const Header = () => {
  const {
    setFormIOContext,
    state: { formContext, currentConfig }
  } = useAppContext();
  const { shouldSwitchContextEnable, auth } = useSelector(state => ({
    shouldSwitchContextEnable: ENABLE_CONTEXT_SWITCHING_ROUTES_NAMES.includes(
      get(state, 'router.location.pathname')
    ),
    auth: state.auth
  }));

  const isAuthenticated = auth.authenticated;
  const dispatch = useDispatch();
  const switchContext =
    formContext === FORM_CONTEXT.Competition
      ? FORM_CONTEXT.Article
      : FORM_CONTEXT.Competition;

  useEffect(() => {
    if (!currentConfig) return;
    // have to re-authenticate the user whenever the user is switching form context
    dispatch(initAuth());
  }, [currentConfig]);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-primary">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img className="logo" alt="Form.io" src={Logo} height="25px" />
        </Link>
        <ul className="nav navbar-nav mr-auto">
          <NavLink
            exact
            to="/"
            role="navigation link"
            className="nav-link ml-3 mr-2"
          >
            Home
          </NavLink>
          {isAuthenticated && (
            <>
              <NavLink
                to="/form/find"
                role="navigation link"
                className="nav-link ml-3 mr-2"
              >
                Search
              </NavLink>
              <NavLink
                to="/form/tags"
                role="navigation link"
                className="nav-link ml-3 mr-2"
              >
                Create New Form
              </NavLink>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://digitalhelp.sca.com.au/"
                className="nav-link ml-3 mr-2"
              >
                Help
              </a>
            </>
          )}
        </ul>
        <ul className="nav navbar-nav ml-auto">
          {isAuthenticated ? (
            <>
              <li className="nav-item cursor-pointer">
                <button
                  type="button"
                  className="nav-link mt-2 btn btn-link"
                  onClick={() => dispatch(logout())}
                >
                  Logout
                </button>
              </li>
              <li className="nav-item cursor-pointer">
                <div className="nav-link">
                  {REACT_APP_ARTICLES_ENABLE === 'true' && (
                    <button
                      type="button"
                      className="btn btn-outline-light"
                      onClick={setFormIOContext(switchContext)}
                      disabled={!shouldSwitchContextEnable}
                    >
                      Switch to {switchContext}
                    </button>
                  )}
                </div>
              </li>
            </>
          ) : (
            <li className="nav-item cursor-pointer">
              <button
                type="button"
                className="nav-link mt-2 btn btn-link"
                onClick={() => dispatch(initAuth())}
              >
                <span className="fa fa-sign-in" />
                &nbsp; Login
              </button>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Header;
