import { Utils as FormioUtils } from 'react-formio';

// Sometimes the Form Builder can take time before it is fully rendered out.
// With the absent of a reliable callback for all builder actions we instead wait until the
// component ID is available in the DOM.
function waitForComponentID(getComponent, key) {
  if (getComponent.id) {
    const node = document.getElementById(getComponent.id);

    if (node) {
      const parentNode = node.parentElement;
      //  disable components
      parentNode.style.opacity = '0.5';
      parentNode.style.pointerEvents = 'none';
      parentNode.style.cursor = 'not-allowed';
      parentNode.style.userSelect = 'none';

      // Hide controls
      const controlNode = node.previousElementSibling;
      if (controlNode) controlNode.style.visibility = 'hidden';
    }
  } else {
    setTimeout(() => waitForComponentID(getComponent, key), 200);
  }
}

function disableBuilderComponents(disableKeys, components) {
  disableKeys.forEach(key => {
    FormioUtils.eachComponent(
      components,
      component => {
        if (component.key && component.key === key) {
          waitForComponentID(component, key);
        }
      },
      true
    );
  });
}

export default disableBuilderComponents;
