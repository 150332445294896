import React, { useEffect } from 'react';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Form } from 'react-formio';
import Loading from '../../../components/Loading';
import { selectBuilderTemplate } from '../../../store/selectors/FormTemplate';
import { getFormTemplate } from '../../../store/actions/FormTemplate';
import { addFormTags } from '../../../store/actions/FormTags';
import PageTitle from '../../../components/PageTitle';
import { useAppContext } from '../../../hooks/useAppContext';

const { REACT_APP_OWNER_KEY, REACT_APP_ADD_TAG_EVENT } = process.env;

function CreateTags() {
  const {
    state: { currentConfig, isLoadingContext }
  } = useAppContext();
  const tagTemplateId = get(currentConfig, 'TAG_TEMPLATE_ID', null);
  const { email, form } = useSelector(state => ({
    email: get(state, 'auth.user.data.email', null),
    form: tagTemplateId ? selectBuilderTemplate(tagTemplateId, state) : null
  }));
  const dispatch = useDispatch();
  useEffect(() => {
    if (tagTemplateId) {
      dispatch(getFormTemplate(tagTemplateId));
    }
  }, [tagTemplateId]);

  const addTags = ({ data, type }) => {
    if (type === REACT_APP_ADD_TAG_EVENT) {
      const createTags = dispatch(
        addFormTags({ ...data, [REACT_APP_OWNER_KEY]: email })
      );
      if (createTags) dispatch(push('/form/template'));
    }
  };

  return isLoadingContext ? (
    <Loading />
  ) : (
    <div>
      <PageTitle title="Add Tags for new Form" />
      {form && (
        <Form form={form} onCustomEvent={submission => addTags(submission)} />
      )}
    </div>
  );
}

export default CreateTags;
