import React from 'react';
import { goBack } from 'connected-react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

function PageTitle({ title, goBackLink, backBtn }) {
  return (
    <>
      <div className="d-flex justify-content-start">
        {backBtn && (
          <ul className="nav mr-3">
            <li className="nav-item">
              <button
                type="button"
                className="btn btn-outline-primary btn-sm nav-link remove-border"
                onClick={goBackLink}
              >
                <i className="fa fa-chevron-left mr-2" />
                Back
              </button>
            </li>
          </ul>
        )}
        <h2 className="font-weight-bold">{title}</h2>
      </div>
      <hr />
    </>
  );
}

const mapDispatchToProps = dispatch => ({
  goBackLink: () => dispatch(goBack())
});

PageTitle.propTypes = {
  title: PropTypes.string,
  goBackLink: PropTypes.func.isRequired,
  backBtn: PropTypes.bool
};

PageTitle.defaultProps = {
  title: '',
  backBtn: true
};

export default connect(null, mapDispatchToProps)(PageTitle);
