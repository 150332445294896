import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

function NavLink({ to, role, className, children, ...rest }) {
  return (
    <li>
      <Link {...{ to, role, className }} {...rest}>
        {children}
      </Link>
    </li>
  );
}

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default withRouter(props => <NavLink {...props} />);
