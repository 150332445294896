import React, { useEffect } from 'react';
import { push } from 'connected-react-router';
import { Form } from 'react-formio';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { selectBuilderTemplate } from '../../../store/selectors/FormTemplate';
import { getFormTemplate } from '../../../store/actions/FormTemplate';
import filterTags from '../../../utilities/tagFilter';
import PageTitle from '../../../components/PageTitle';
import { useAppContext } from '../../../hooks/useAppContext';

const { REACT_APP_OWNER_KEY } = process.env;

const FindForm = () => {
  const {
    state: { currentConfig }
  } = useAppContext();
  const findFormTemplateId = get(currentConfig, 'FIND_FORM_TEMPLATE_ID', null);
  const { email, form } = useSelector(state => ({
    email: (state.auth.user && state.auth.user.data.email) || null,
    form: findFormTemplateId
      ? selectBuilderTemplate(findFormTemplateId, state)
      : null
  }));
  const dispatch = useDispatch();
  useEffect(() => {
    if (findFormTemplateId) {
      dispatch(getFormTemplate(findFormTemplateId));
    }
  }, [findFormTemplateId]);

  const searchForm = ({ data }) => {
    const tags =
      email && data[REACT_APP_OWNER_KEY]
        ? filterTags(Object.assign(data, { [REACT_APP_OWNER_KEY]: email }))
        : filterTags(data);
    dispatch(push({ pathname: '/form/view', state: { tags } }));
  };

  return (
    <div>
      <PageTitle title="Find Form" />
      {form && (
        <Form
          form={form}
          onCustomEvent={submission => searchForm(submission)}
        />
      )}
    </div>
  );
};

export default FindForm;
