import React from 'react';
import { useSelector } from 'react-redux';
import { selectRoot } from 'react-formio';
import get from 'lodash/get';
import Hero from '../components/Hero';
import FormList from './Form/FormList';

function AuthenticatedView() {
  const auth = useSelector(state => selectRoot('auth', state));
  return (
    <>
      {get(auth, 'user.data') && (
        <>
          <h5 className="float-right">
            You are logged in as <strong>{auth.user.data.email}</strong>
          </h5>
          <div className="mt-5 mb-5">
            <FormList title="My Forms" backBtn={false} />
          </div>
        </>
      )}
    </>
  );
}

const Home = () => (
  <div>
    <Hero />
    <div className="container">
      <AuthenticatedView />
    </div>
  </div>
);

export default Home;
