import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { forms, submission, submissions } from 'react-formio';
import form from './Form';
import formTemplate from './FormTemplate';
import formAction from './FormAction';
import auth from './Auth';
import tags from './Tags';

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth: auth(),
    form: form({ name: 'form' }),
    tags: tags(),
    formTemplate: formTemplate(),
    forms: forms({ name: 'forms' }),
    submission: submission({ name: 'submission' }),
    submissions: submissions({ name: 'submissions' }),
    event: combineReducers({
      form: form({ name: 'event' }),
      submission: submission({ name: 'event' }),
      submissions: submissions({ name: 'event' })
    }),
    action: formAction({ name: 'form' })
  });
